Ember.TEMPLATES["javascripts/discourse-akismet/connectors/flag-modal-bottom/akismet-status"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"akismet_state\"]],[[[1,\"  \"],[10,0],[14,0,\"consent_banner alert alert-info\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,2],[[28,[37,3],[\"akismet.post_state.\",[33,1,[\"akismet_state\"]]],null]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"post\",\"i18n\",\"concat\"]]","moduleName":"javascripts/discourse-akismet/connectors/flag-modal-bottom/akismet-status","isStrictMode":false});
define("discourse/plugins/discourse-akismet/discourse-akismet/connectors/flag-modal-bottom/akismet-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    setupComponent: function setupComponent(args, component) {
      component.set("post", args.post);
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse-akismet/connectors/topic-above-post-stream/topic-removed-notification"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"alert alert-info category-read-only-banner\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"akismet.topic_deleted\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"akismetFlaggedTopic\",\"i18n\"]]","moduleName":"javascripts/discourse-akismet/connectors/topic-above-post-stream/topic-removed-notification","isStrictMode":false});
define("discourse/plugins/discourse-akismet/discourse-akismet/connectors/topic-above-post-stream/topic-removed-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    setupComponent: function setupComponent(args, component) {
      var akismetDeletedTopicChannel = "/discourse-akismet/topic-deleted/".concat(args.model.id);
      component.messageBus.subscribe(akismetDeletedTopicChannel, function () {
        component.set("akismetFlaggedTopic", true);
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-akismet/discourse/initializers/add-akismet-state", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "add-akismet-state",
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", function (api) {
        api.includePostAttributes("akismet_state");
      });
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/reviewable-akismet-api-error"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[14,0,\"reviewable-score-reason\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"admin.akismet_api_error\"],null]],[1,\"\\n  \"],[1,[33,1,[\"error\"]]],[1,\"\\n  (\"],[1,[33,1,[\"code\"]]],[1,\")\\n  \"],[1,[33,1,[\"msg\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"i18n\",\"external_error\"]]","moduleName":"javascripts/discourse/templates/components/reviewable-akismet-api-error","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/reviewable-akismet-post"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"reviewable\",\"tagName\"],[[33,1],\"\"]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"user\",\"tagName\"],[[33,1,[\"target_created_by\"]],\"\"]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"user\",\"tagName\"],[[33,1,[\"target_created_by\"]],\"\"]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"post-body\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[[33,1,[\"payload\",\"post_cooked\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,1,null],[1,\"\\n\\n\"],[41,[33,1,[\"payload\",\"external_error\"]],[[[1,\"      \"],[1,[28,[35,7],null,[[\"external_error\"],[[33,1,[\"payload\",\"external_error\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"reviewable-topic-link\",\"reviewable\",\"reviewable-created-by\",\"reviewable-created-by-name\",\"html-safe\",\"yield\",\"if\",\"reviewable-akismet-api-error\"]]","moduleName":"javascripts/discourse/templates/components/reviewable-akismet-post","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/reviewable-akismet-user"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[14,0,\"reviewable-user-info\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"reviewable-user-fields\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"reviewable-user-details username\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"name\"],[12],[1,[28,[35,0],[\"review.user.username\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n\"],[41,[33,2,[\"user_deleted\"]],[[[1,\"          \"],[1,[33,2,[\"payload\",\"username\"]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,3],[15,6,[28,[37,3],[[28,[37,4],[\"/u/\",[33,2,[\"payload\",\"username\"]],\"/summary\"],null]],null]],[12],[1,\"\\n            \"],[1,[33,2,[\"payload\",\"username\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,5],null,[[\"classes\",\"name\",\"value\"],[\"reviewable-user-details name\",[28,[37,0],[\"review.user.name\"],null],[33,2,[\"payload\",\"name\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,5],null,[[\"classes\",\"name\",\"value\"],[\"reviewable-user-details email\",[28,[37,0],[\"review.user.email\"],null],[33,2,[\"payload\",\"email\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,5],null,[[\"classes\",\"name\",\"value\"],[\"reviewable-user-details bio\",[28,[37,0],[\"review.user.bio\"],null],[33,2,[\"payload\",\"bio\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[18,1,null],[1,\"\\n\\n\"],[41,[33,2,[\"payload\",\"external_error\"]],[[[1,\"    \"],[1,[28,[35,7],null,[[\"external_error\"],[[33,2,[\"payload\",\"external_error\"]]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"i18n\",\"if\",\"reviewable\",\"get-url\",\"concat\",\"reviewable-field\",\"yield\",\"reviewable-akismet-api-error\"]]","moduleName":"javascripts/discourse/templates/components/reviewable-akismet-user","isStrictMode":false});

